@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 2px solid #f2f2f2ef;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;

}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
}
.content {
  max-width: 600px;
  margin: 20px auto;
  padding: 10px;
}

/* home */
.home{
  padding-left: 5px;
}
.home-page-message-seo{
  padding-bottom: 40px;
}
.home-page-message{
  padding-top: 150px;
}
.home-page-message h4{
  padding-bottom: 10px;
}
.home-page-message p{
  padding-top: 10px;
}

.home-page-direct-button{
  background: #f1356d;
  padding: 10px 60px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
} 

/* paper preview /list */
.paper-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}
.paper-preview:hover{
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 1);
}
.paper-preview h2{
  font-size: 20px;
  color: #f1356d;
  margin-bottom:8px ;
}
.paper-preview p{
  font-size: 10px;
  color: #3c0918;
  margin-bottom:8px ;
}
.paper-preview a{
  text-decoration: none;
}

/* paper details page */
.paper-details h2{
  font-size: 20px;
  color: #f1356d;
  margin-bottom: 10px;
  padding-left: 5px;
}
.paper-details div{
  margin: 20px 0;
}
.paper-details button{
  background: #f1356d;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
}

.paper-list a{
  font-size: 16px;
  padding: 8px;
}
.paper-list{
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  
}
.paper-list h2{
  padding: 5px;
}

/* footer */
.footer{
  margin-top: 40px;
}

.footer p{
  padding-top: 10px;
}
